import { render, staticRenderFns } from "./file-element.vue?vue&type=template&id=2535b5e9&scoped=true"
import script from "./file-element.vue?vue&type=script&lang=js"
export * from "./file-element.vue?vue&type=script&lang=js"
import style0 from "./file-element.vue?vue&type=style&index=0&id=2535b5e9&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2535b5e9",
  null
  
)

export default component.exports